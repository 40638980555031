/* INTAKE Q */

.intakeqImg {
  height: 45px; width: 162px;
  color: black;
  border-radius: 5px;
}

.intakeq {
  max-width:720px; width: 100%;
}

/* Buttons */

.bookAppLink {
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 10px;
  background-color: rgb(221, 212, 215);
  color: #000000;
}

.bookAppLink:hover {
  background-color: rgba(159, 161, 161, 0.3);
  color: rgb(255, 255, 255);
  transition: .8s;
}