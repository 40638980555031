.width1024 {
  width: 1024px;
  margin: auto;
}

.aboutContainer {
  padding-bottom: 40px;
}

/* ##### Intro ##### */
.introSection {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* ANY MODIFICATIONS MUST BE CHANGED AS WELL BELOW */
div + .infoParagraph {
  color: rgb(108, 116, 158);
  /* border: red solid 5px; */
  font-size: 2em;
  padding: 5px 0;
}

/* RESET ANY MODIFICATIONS FROM ABOVE */
div + div + .infoParagraph {
  font-size: 1em;
  color: rgb(0, 0, 0);
  /* border: red solid 5px; */
}

.introButton {
  background-color: rgb(108, 116, 158);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 9px 15px;
  font-size: 1.4em;
  margin: 40px 15px 50px 0;
  display: inline-block;
}

.introButton:hover {
  color: black;
  background-color: rgb(219, 215, 215);
}

/* ##### Hours ##### */

.hours {
  background-color: rgb(221, 212, 215);
  padding-bottom: 40px;
}

.busHours {
  font-size: 2em;
  color: rgb(108, 116, 158);
  padding: 40px 0;
}

.busHours > span {
  margin-left: 20px;
}

.gridItem {
  margin: 10px auto;
  width: 500px;
}

.hourDay {
  font-weight: bold;
}

/* ##### contact ###### */

.contactSection {
  padding: 50px 0;
}

.line {
  width: 150px;
  border-bottom: 1.5px solid black;
  margin: 30px 0;
}

.appContact {
  font-size: 2em;
  font-weight: 350;
}

.phoneNumber {
  text-decoration: underline;
}


/* ##### Policy ##### */

.poicySection {
  margin-top: 30px;
}

.policyTitle {
  border-bottom: 2px solid rgb(221, 212, 215);
  width: fit-content;
  margin: 10px 0;
  font-size: 1.7em;
}

.policyInfo {
  padding: 5px 0;
}

.policyIntro {
  color: rgb(108, 116, 158);
  font-weight: 400;
  font-size: 2em;
}

.endOfAbout {
  padding-bottom: 80px;

}

/* ########## Media ########## */

@media (max-width: 1400px) {
  .aboutContainer {
    width: 100%;
    /* height: 140vh; */
    font-size: 1.35em;
  }
}

@media (max-width: 1024px) {
  .aboutContainer {
    width: 100%;
  }
.width1024 {
    width: 100%;
  }
  .hours {
    padding: 25px;
    /* width: 770px; */
  }
}


@media (max-width: 780px) {
  .aboutContainer {
    width: 100%;
  }
  .width1024 {
    width: 100%;
  }
  .gridItem > div {
    display: flex;
    justify-content: center;
    /* width: 700px; */
  }
  
  .gridItem {
    padding-bottom: 25px;
    width: 100%;
  }

  .busHours {
    display: flex;
    justify-content: center;
  }

  
}


@media (max-width: 425px) {

  .width1024 {
    width: 100%;
    margin: 10px;
  }
  .aboutContainer {
    width: 90%;
    /* height: 140vh; */
    font-size: 1.5em;
    /* padding: 15px; */
  }
  .gridItem {
    width: 100%;
  }

  .gridItem > div {
    width: 90%;
  }

  .hours {
    /* margin: 0 -15px; */
    /* width: 105.5%; */
  }

  .introSection > section {
    /* display: inline-block; */
    width: fit-content;
    margin: 20px auto;
    margin-right: 10px;
  }

  /* .introSection:first-of-type > section {
    margin-right: 100px;
    border: red solid 5px;
  } */
  
  div + .infoParagraph {
    display: flex;
    justify-content: center;
  }
  
  .infoParagraph:first-child {
    display: flex;
    justify-content: center;
  }
  
  
  .hourDay, .hourTime {
    margin: 0 10px;
    width: 90%;
  }
  
  .hourTime {
    margin-bottom: 20px;
  }

  .busHours {
    margin: 10px 0;
    width: 90%;
  }
}

@media (max-width:375px) {

  .aboutContainer {
    width: 100%;
  }
  .hours {
    width: 100%;
    /* margin-right: 300px; */
  }
  .width1024 {
    /* width: 340px; */
    margin: auto;
  }
  .gridItem {
    /* width: 300px; */
  }

  .gridItem > div {
    width: 100%;
    margin: auto;
  }

  .gridItem > div + div{
    display: flex;
    justify-content: center;
    margin-left: 15%;
    margin-right: 15%;
    width: auto;
  }

  .hourTime {
    display: flex;
    justify-content: center;
  }
}
