.mainBackground {
  background: url("../../images/landing/landingPage.png") no-repeat center center fixed;
  position: relative;
  object-fit: cover;
  background-size: cover;
  width: 100%;
}

.main {
  height: 100vh;
  text-align: center;
}

.opening {
  height: 20vh;
  margin: auto;
}

/* ##### Button to book massage ##### */
.MTSessionButton {
  margin: 10px;
  padding: 5px;
  float: left;
  text-decoration: none;
  padding: 10px;
  border: rgb(255, 255, 255) solid 2px;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

.MTSessionButton:hover {
  border: rgb(255, 255, 255) solid 3px;
  padding: 9px;
  background-color: rgb(255, 255, 255, 0.1);
  color: rgb(255, 255, 255);
  transition: .8s;
}


.span {
  display: inline-block;
}

.intro {
  color: rgb(255, 255, 255);
}

.titleTextLeft {
  font-size: 4em;
  text-align: left;
  text-shadow: 2px 1.5px #4e4e4d;
  margin: 0px 70px;
}

.titleTextRight {
  position: relative;
  bottom: 200px;
}

.titleTextRight > div {
  font-size: 1.2em;
}


@media only screen and (max-width: 1400px) {
  .titleTextRight {
    display: block;
    position: relative;
    top: 0px;
  }

  .intro {
    margin-bottom: 50px;
  }
  .MTSessionButton {
    float: none;
  }
  .mainBackground {
    height: 130vh;
  }
  .titleTextLeft {
    margin-bottom: 50px;
    margin: 0 10px;
  }
}

@media (max-width: 425px) {
  .mainBackground {
    width: 100%;
    height: 140vh;
  }
  .MTSessionButton {
    margin-bottom: 300px;
  }
  .titleTextLeft {
    margin-bottom: 50px;
    font-size: 2.8em;
    margin: 0 10px;
  }
}


@media (max-width:390px) {
  .mainBackground {
    margin-top: -5px;
    /* width: 390px; */
    height: 140vh;
  }
}
