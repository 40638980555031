footer {
  width: 100%;
  padding-bottom: 40px;
  margin-top: 10px;
  /* display: block;
  position: relative;
  margin-top: 3500px; */
}

.navContainer2 > span:first-child {
  /* margin-left: -40px; */
  margin: auto;
}

.navContainer2 > span:last-child {
  /* margin-right: 15px; */
}

.linkComponent2 {
  text-decoration: none;
  color: grey;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
}

.linkComponent2:hover {
  background-color: rgb(174, 174, 175);
  color: rgb(108,116,158);
  transition: .8s;
}

.navContainerFooter {
  display: inline-block;
}

.navContainer2 {
  display: inline-block;
  padding: 15px 0px;
}

.footerContainer {
  width: 90%;
  margin: auto;
}

.footerBusName {
  /* display: inline-block; */
  float: right;
  font-size: 1.2em;
  color: rgb(108, 116, 158);
}

/* .busInfo {
  width: 50vw;
  margin: auto;
  display: flex;
  justify-content: center;
}

.busInfo > div {
  display: block;
} */

/* .busInfo > div:first-child {
  font-size: larger;
  margin-top: 10px;
} */

.Headers {
  font-size: larger;
  margin-top: 10px;
}

.busInfo > div {
  margin-bottom: 10px;
}

.emailLink {
  color: black;
}

.copyright {
  color: grey;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.copyright + div {
  margin-top: 0px;
}

.addressLink {
  margin-bottom: 10px;
  width: fit-content;
}

.logos {
  display: flex;
  justify-content: center;
}

.logos > span {
  margin-right: 50px;
}

.chamberOfCommerce {
  /* display: inline-block;  */
  /* width: 100%;  */
  /* text-align: center; */
}

.chamberOfCommerceImg {
  max-width:220px; 
  margin-top: 20px; 
  margin-top: 20px;
}

.BBB {
  border: 0;
  margin-top: 20px;
}

.footerHours {
  min-width: 120px;
  margin-right: 10px;
  /* border: red solid 5px; */
}

.hoursSection {
  /* border: blue solid 5px; */
  max-width: 340px;
}

@media  (max-width: 780px) {
  /* footer {
    font-size: 1.3em;
  } */
  .logos {
    display: block;
  }

  .logos > span {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  /* footer {
    font-size: 1.3em;
  } */

  .envelope {
    display: none;
  }
  .logos {
    display: block;
  }

  .emailLink {
    font-size: 0.8em;
  }
}